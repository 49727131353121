// Libs
import i18n from '@i18n';

// Types
import { SelectOptions } from '@components/Formik/Select';
import { ConferenceType } from '@domain/districts/types';

const { t } = i18n;

export const getConferenceTypeSelectOptions = (): SelectOptions => [
  {
    label: t('create-conference.in-person-only', 'In person'),
    value: ConferenceType.Physical,
  },
  {
    label: t('create-conference.online-only', 'Online'),
    value: ConferenceType.Online,
  },
  {
    label: t('create-conference.in-person-and-online', 'In person and online'),
    value: ConferenceType.Hybrid,
  },
];
